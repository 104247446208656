import {
  type ComponentFieldsFragment as ComponentFields,
  type CoreFieldsFragment as CoreFields,
  type ModuleFieldsFragment as ModuleFields,
  type PageFieldsFragment as PageFields,
} from '@backstage/attendee-ui-types';
import {DynamicComponent} from '@backstage-components/base';
import {
  computeId,
  FamilyTreeNode,
  familyTree,
  GroupInstanceData,
  getModuleProps,
  mergeProps,
} from '@backstage/module-tree-shared';
import {config} from '../../config';
import {PageData} from './usePageListings';
import {arrayToLookup, isJSONObject} from '../../utils';

type AllPageParts = {
  components: Record<string, ComponentFields>;
  cores: Record<string, CoreFields>;
  modules: Record<string, ModuleFields>;
};

function nodeToPageModule(
  node: FamilyTreeNode,
  page: PageFields,
  all: AllPageParts,
  tree: FamilyTreeNode[],
  vars: JSONValue = {},
  groupInstanceData?: GroupInstanceData | null
): DynamicComponent {
  vars = Object.assign({}, vars);
  const module = all.modules[node.id];
  const core = all.cores[module.coreId];
  const component = all.components[core.componentId];
  const props = core.componentFieldData;

  if (typeof props !== 'object' || Array.isArray(props) || props === null) {
    throw new Error(
      `componentFieldData for Module(${node.id}) was not an object.`
    );
  }

  /*
   * Props are mainly stored in core.componentFieldData, but there are overrides
   * and one "underride" that's not been written yet.
   * -1 "presets" are set before core's props. But overrides can change the preset.
   *  0 core props "componentFieldData"
   *  1 moduleProps which are overrides at the module level
   *  2 staffProps which are overrides at the group level. When we allow groups within
   *    groups, this will be layered more, but those will come through the same object.
   */

  // If this module isn't in a group, then it can have no staff overrides.
  // Currently, we cannot have embedded groups, so this is a safe place to
  // identify the group boundary.
  if (module.path.length > 0) {
    groupInstanceData = null;
  }

  // These only get populated by the top level of a group; staff modules or ungrouped will get nulls here.
  const {moduleProps, staffOverrides} = getModuleProps(core, module);
  if (core.coreType === 'group') {
    groupInstanceData = {
      groupId: core.id,
      groupModuleId: module.id,
      overrides: staffOverrides ?? null,
    };
  }

  // If overrides exist, see if any are applicable to this module.
  const staffProps = module.groupId
    ? groupInstanceData?.overrides?.[node.id] ?? null
    : null;

  const propsToUse = mergeProps([props, moduleProps, staffProps]);

  // Since we're building up based on family tree, we don't need all this.
  // NEXT: variableResolver(vars, module.variables, core.variables, core.componentFieldData);
  // Unlike modules and cores, components don't need to be cloned, I think.
  // TODO: Add i18n to this.
  // const props = Object.assign(
  //   {},
  //   isJSONObject(core.componentFieldData) ? core.componentFieldData : {},
  //   isJSONObject(core.i18n?.[1]?.data) ? core.i18n?.[1]?.data : {},
  //   isJSONObject(core.i18n?.[0]?.data) ? core.i18n?.[0]?.data : {}
  // );

  // pre-compute these for later.
  const mid = module.id;
  const groupModuleId =
    (groupInstanceData?.groupModuleId !== mid &&
      groupInstanceData?.groupModuleId) ||
    null;

  const rv: DynamicComponent = {
    component: component.reactName,
    config: {
      scope: 'attendee',
      attendeeApiEndpoint: `${config.endpointBase}/attendee`,
      legacyEndpoint: config.legacyEndpoint,
      bitmovinKey: config.bitmovinKey,
      streamKey: config.streamKey,
      timestampEndpoint: `${config.endpointBase}/timestamp`,
    },
    id: computeId({mid, groupModuleId}),
    mid,
    cid: core.id,
    gid: module.groupId || null,
    groupModuleId,
    path: module.path || [],
    props: propsToUse,
  };

  node.groupCoreId = rv.gid;
  node.groupModuleId = rv.groupModuleId;
  node.htmlId = rv.id;
  node.slot = module.parentSlotSlug;

  if (isJSONObject(props) && 'styleAttr' in props) {
    rv.style = propsToUse.styleAttr?.toString().replace(/\r?\n/g, ' ');
  }

  if ('kids' in node && node.kids) {
    rv.slots = {};
    node.kids.forEach((kid) => {
      const km = all.modules[kid.id];
      if (rv.slots && km.parentSlotSlug) {
        let slot = rv.slots[km.parentSlotSlug];
        if (!slot) {
          slot = rv.slots[km.parentSlotSlug] = [];
        }
        if (Array.isArray(slot)) {
          slot.push(
            nodeToPageModule(kid, page, all, tree, vars, groupInstanceData)
          );
        }
      }
    });
  }

  return rv;
}

const setAttributeIfRelevant = (
  node: Element,
  attr: string,
  value: string | null | undefined
): void => {
  if (value) {
    node.setAttribute(attr, value);
  }
};

const makeXmlNode = (
  document: XMLDocument,
  parent: Element,
  treeNodes: FamilyTreeNode[]
): void => {
  treeNodes.forEach((treeNode) => {
    const xmlNode: Element = document.createElement(treeNode.kind);
    xmlNode.id = treeNode.htmlId ?? '';
    xmlNode.setAttribute('coreId', treeNode.cid);
    xmlNode.setAttribute('moduleId', treeNode.id);
    setAttributeIfRelevant(xmlNode, 'slot', treeNode.slot);
    setAttributeIfRelevant(xmlNode, 'groupModuleId', treeNode.groupModuleId);
    setAttributeIfRelevant(xmlNode, 'groupCoreId', treeNode.groupCoreId);
    parent.appendChild(xmlNode);
    if ('kids' in treeNode) {
      makeXmlNode(document, xmlNode, treeNode.kids);
    }
  });
};

function buildXmlModel(page: PageFields, tree: FamilyTreeNode[]): XMLDocument {
  const doc = document.implementation.createDocument(null, 'Root', null);
  // console.log('✨✨', doc, tree, page);
  const root = doc.children.item(0);
  if (root !== null) {
    root.setAttribute('id', 'root');
    root.setAttribute('pathname', page.pathname);
    root.setAttribute('showId', '');
    root.setAttribute('domain', '');
    makeXmlNode(doc, root, tree);
  }
  return doc;
}

export function buildUpModules(page: PageFields): PageData {
  const all: AllPageParts = {
    components: arrayToLookup(page.allComponents, 'id'),
    cores: arrayToLookup(page.allCores, 'id'),
    modules: arrayToLookup(page.allModules, 'id'),
  };
  const rootModules = page.allModules.filter((x) => x.pageIndex !== null);
  const tree = familyTree(rootModules, all);

  const modules: DynamicComponent[] = tree.map((x) =>
    nodeToPageModule(x, page, all, tree)
  );

  const structure = buildXmlModel(page, tree);

  return Object.assign({}, page, {modules, structure});
}
