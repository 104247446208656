import {
  PageFieldsFragment as PageFields,
  QuerySiteDetailsByDomain,
  type SiteDetailsByDomainQuery as SiteForPageListing,
  type SiteDetailsByDomainQueryVariables as SiteForPageListingVariables,
} from '@backstage/attendee-ui-types';
import {DynamicComponent} from '@backstage-components/base';
import {useEffect, useMemo, useState} from 'react';
import {LazyQueryResult, useLazyQuery} from '@apollo/client';
import {updateAccessToken} from '../../apollo';
import {buildUpModules} from './moduleTree';
import {useShowId} from './useShowId';
import {useSiteVersionId} from './useSiteVersionId';

type Site = SiteForPageListing['site'];

export type PageData = PageFields & {
  modules: DynamicComponent[];
  structure: XMLDocument;
};

interface PageListingResult
  extends Pick<
    LazyQueryResult<SiteForPageListing, SiteForPageListingVariables>,
    'called' | 'error' | 'loading'
  > {
  data: SiteResult | undefined | null;
}

export interface SiteResult extends Omit<SiteForPageListing, 'site'> {
  site: Omit<Site, 'items'> & {items: PageData[]};
}

export const usePageListings = (domainName?: string): PageListingResult => {
  // `data` is set from the `Promise` returned by calling query via apollo
  const [data, setData] = useState<SiteForPageListing | undefined | null>(
    undefined
  );
  const showId = useShowId(data?.site);
  const siteVersionId = useSiteVersionId() ?? null;

  // fetch page data, extract showId and add to context for AC
  const [getSitePageListings, {called, error, refetch}] = useLazyQuery<
    SiteForPageListing,
    SiteForPageListingVariables
  >(QuerySiteDetailsByDomain, {
    fetchPolicy: 'network-only',
    context: {showId},
  });

  // Set up listener for 'AccessCode:success' event and perform initial fetch
  useEffect(() => {
    if (typeof showId === 'undefined') {
      return;
    }
    // Refetch pages on access token change
    const onAccessCodeSuccess = (e: Event): void => {
      if (e.type === 'AccessCode:success') {
        updateAccessToken(showId);
        setData(undefined);
        refetch().then((r) => setData(r.data));
      }
    };
    document.body.addEventListener('AccessCode:success', onAccessCodeSuccess);
    return () => {
      document.body.removeEventListener(
        'AccessCode:success',
        onAccessCodeSuccess
      );
    };
  }, [domainName, refetch, showId]);
  // Refetch data when `domainName` changes
  useEffect(() => {
    if (typeof domainName === 'undefined') {
      return;
    }
    getSitePageListings({
      variables: {domainName, versionId: siteVersionId},
    }).then((r) => setData(r.data));
  }, [domainName, getSitePageListings, siteVersionId]);

  const resultData = useMemo(() => {
    // no data yet or error fetching data
    if (typeof data === 'undefined' || data === null) {
      return data;
    }
    const pageData = data.site.pages.map(
      (item): PageData => buildUpModules(item)
    );
    const result: SiteResult = {
      site: {
        ...data.site,
        items: pageData,
      },
    };
    return result;
  }, [data]);

  return {
    data: resultData,
    called,
    error,
    loading: typeof resultData === 'undefined',
  };
};
