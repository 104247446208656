import type {SiteDetailsByDomainQuery} from '@backstage/attendee-ui-types';
import {useMemo} from 'react';

type Site = SiteDetailsByDomainQuery['site'];

const uuidRegex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

/*
 * Parses Url to find valid show associated with valid site
 */
export function useShowId(site: Site | undefined): string | undefined {
  const [, , pathShowId, ...path] = window.location.pathname.split('/');
  const showId = useMemo(
    () => extractShowId([pathShowId, ...path], site),
    [site, pathShowId, path]
  );

  return showId;
}

function extractShowId(
  path: string[],
  site: Site | undefined
): string | undefined {
  const candidate = path[0];
  const isValidUUID = candidate ? uuidRegex.test(candidate) : false;
  if (isValidUUID && site?.shows.find((e) => e.id === candidate)) {
    return candidate;
  } else if (site && site.shows.length > 0) {
    return site.shows[0].id;
  } else {
    return undefined;
  }
}
