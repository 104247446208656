import {Type} from '@sinclair/typebox';

export const styleAttr = Type.Optional(
  Type.String({
    title: 'Style attribute',
    description:
      'Use `.lcd-preview-only & { //styles }` to apply styles specific to the layout editor.',
  })
);

export const styleAttrUi = {
  styleAttr: {
    'ui:widget': 'modalTextareaWidget',
    'ui:options': {
      rows: 5,
      buttonTitle: 'Custom Styles',
      isCodeMirror: true,
    },
  },
};
