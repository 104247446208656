import {VFC} from 'react';
import {ChakraProvider, Spinner, Center} from '@chakra-ui/react';
import {motion} from 'framer-motion';

const FullBackground = motion(Center);
const loaderContainer = {
  hidden: {
    transitionEnd: {
      display: 'none',
    },
    opacity: 0,
    transition: {
      duration: 1,
      delay: 1,
    },
  },
  show: {
    display: 'flex',
    opacity: 1,
  },
};

const fullBackgroundStyles = {
  backgroundColor: '#191919',
  height: '100%',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999,
  css: 'height: -webkit-fill-available;',
};

export const PageLoader: VFC<PageLoaderProps> = ({isLoading}) => {
  return (
    <ChakraProvider>
      <FullBackground
        {...fullBackgroundStyles}
        variants={loaderContainer}
        initial="show"
        animate={isLoading ? 'show' : 'hidden'}
      >
        <Spinner
          width="6.25rem"
          height="6.25rem"
          size="xl"
          speed="0.85s"
          color="rgba(255,255,255,0.85)"
          emptyColor="rgba(255,255,255,0.1)"
        />
      </FullBackground>
    </ChakraProvider>
  );
};

interface PageLoaderProps {
  isLoading: boolean;
}
