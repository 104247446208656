import Cookie from 'js-cookie';
import {useEffect, useMemo} from 'react';
import {config} from '../../config';

/**
 * Parses `Location` to find domain name.
 * @returns the value to use as the domain of the site.
 */
export function useDomainName(): LocationDetails | undefined {
  const location = typeof window === 'undefined' ? undefined : window.location;
  const result = useMemo<LocationDetails | undefined>(() => {
    if (!location) {
      return undefined;
    } else if (isLivePreviewHost(location)) {
      const [, showId] = location.pathname.split('/');
      const queryParams = new URLSearchParams(location.search);
      const domainName =
        queryParams.get('host') ?? Cookie.get('host') ?? location.hostname;
      return {isPreview: false, domainName, showId};
    } else if (isPreviewDomain(location)) {
      const [, domainName, showId] = location.pathname.split('/');
      return {isPreview: true, domainName, showId};
    } else {
      const [, showId] = location.pathname.split('/');
      return {isPreview: false, domainName: location.hostname, showId};
    }
  }, [location]);
  useEffect(() => {
    if (result?.domainName && isLivePreviewHost(location)) {
      Cookie.set('host', result?.domainName, {
        sameSite: 'strict',
        secure: true,
      });
    }
  }, [location, result?.domainName]);
  return result;
}

export interface LocationDetails {
  isPreview: boolean;
  domainName: string;
  showId: string;
}

/**
 * Tests if the current domain is a preview domain. A preivew domain indicates
 * that the "domain" of the site is part of the pathname.
 *
 * **WARNING** This makes an assumption about how the `@backstage/ui` package
 * connects to the API and how that is related to the preview / published
 * relationship. For now this assumption is valid, but it may not be in the
 * future.
 *
 * TODO: This should go away once site publishing is up and running. Published
 * sites will _always_ use the domain from the hostname and once that is true
 * `@backstage/ui` should _always_ use the domain from the pathname.
 *
 * @param location object with hostname information to check.
 * @returns `true` if the hostname of `location` should be treated as a preview
 * domain, indicating that the "domain" of the site is a part of the pathname.
 * Always `true` on "localhost".
 */
function isPreviewDomain(location?: Location): boolean {
  if (typeof location === 'undefined') {
    return false;
  }
  const hostname = location.hostname || location.host;
  // if the endpoint looks like a path, pass the current domain as the base of
  // the endpoint
  const urlBase = config.endpointBase.startsWith('/')
    ? `${location.protocol}//${location.hostname}`
    : undefined;
  const endpoint = new URL(config.endpointBase, urlBase);
  const endpointHostnameParts = endpoint.hostname.split('.');
  const endpointBase =
    endpointHostnameParts.length >= 3
      ? endpoint.hostname.split('.').slice(1).join('.')
      : endpoint.hostname;
  return (
    hostname === 'localhost' ||
    hostname.endsWith(endpointBase) ||
    hostname.startsWith('preview.')
  );
}

/**
 * Tests if the current domain is a "live preview" domain. A "live preview"
 * domain indicates that the "domain" of the site is either the location
 * hostname or passed as a query parameter.
 *
 * **WARNING** This makes an assumption about how the `@backstage/ui` package
 * connects to the API and how that is related to the live preview / published
 * relationship. For now this assumption is valid, but it may not be in the
 * future.
 *
 * @param location object with hostname information to check.
 * @returns `true` if the hostname of the `location` should be treated as a
 * "live preview" domain.
 */
function isLivePreviewHost(location?: Location): boolean {
  if (typeof location === 'undefined') {
    return false;
  }
  const hostname = location.hostname || location.host;
  // if the endpoint looks like a path, pass the current domain as the base of
  // the endpoint
  const urlBase = config.endpointBase.startsWith('/')
    ? `${location.protocol}//${location.hostname}`
    : undefined;
  const endpoint = new URL(config.endpointBase, urlBase);
  const endpointHostnameParts = endpoint.hostname.split('.');
  const endpointBase =
    endpointHostnameParts.length >= 3
      ? endpoint.hostname.split('.').slice(-2).join('.')
      : endpoint.hostname;
  return hostname.endsWith(endpointBase) && hostname.startsWith('live.');
}
