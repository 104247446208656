import {
  QueryShowInstructions,
  type ShowInstructionsQuery as GetShowInstructionsQuery,
  type ShowInstructionsQueryVariables as GetShowInstructionsVariables,
} from '@backstage/attendee-ui-types';
import {AttendeeProvider} from '@backstage-components/attendee-container';
import type {
  AppPage,
  GetShowInstructionsResult,
} from '@backstage-components/base';
import {ShowInstructionsProvider} from '@backstage-components/base';
import {ChakraProvider} from '@chakra-ui/react';
import {FC} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {client} from './apollo';

const fetchInstructions = (
  id: string,
  sinceInstructionId: string | null = null
): Promise<GetShowInstructionsResult> => {
  return client
    .query<GetShowInstructionsQuery, GetShowInstructionsVariables>({
      query: QueryShowInstructions,
      variables: {showId: id, sinceInstructionId},
      context: {showId: id},
      fetchPolicy:
        typeof sinceInstructionId === 'undefined'
          ? 'cache-first'
          : 'network-only',
    })
    .then((result) => {
      return {
        data: result.data,
        error: result.error,
      };
    });
};

export const AppContext: FC<AppContextProps> = ({
  appPages,
  children,
  showId,
}) => {
  return typeof showId !== 'undefined' ? (
    <ChakraProvider>
      <ShowInstructionsProvider
        appPages={appPages}
        fetchInstructions={fetchInstructions}
        showId={showId}
      >
        <AttendeeProvider client={client} showId={showId}>
          <HelmetProvider>{children}</HelmetProvider>
        </AttendeeProvider>
      </ShowInstructionsProvider>
    </ChakraProvider>
  ) : (
    <ChakraProvider>
      <HelmetProvider>{children}</HelmetProvider>
    </ChakraProvider>
  );
};

interface AppContextProps {
  appPages: AppPage[];
  showId: string | undefined;
}
