import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import type {Operation} from '@apollo/client';
import Cookie from 'js-cookie';
import {config} from './config';

export function updateAccessToken(showId: string): void {
  const token = readAccessToken(showId);
  if (token !== null) {
    Cookie.set('session-token', token, {
      expires: 1, // expires in one day
      sameSite: 'strict',
      secure: true,
    });
    localStorage.setItem(`lcd-ac/${showId.slice(0, 8)}/access-token`, token);
  }
}

const httpLink = new HttpLink({
  uri: `${config.endpointBase}/attendee`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const {showId} = operation.getContext();
  const accessToken = readAccessToken(showId);
  if (accessToken !== null) {
    applyAccessToken(operation, accessToken);
  }
  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authMiddleware, httpLink]),
});

function readAccessToken(showId: unknown): string | null {
  const initialToken = Cookie.get('session-token');
  if (typeof showId === 'string') {
    return localStorage.getItem(`lcd-ac/${showId.slice(0, 8)}/access-token`);
  } else {
    return initialToken ?? null;
  }
}

function applyAccessToken(operation: Operation, token: string): void {
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
}
