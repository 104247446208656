import styled from '@emotion/styled';
import {IAccessCodeProps} from './AccessCodeComponent';

type AccessCodeStyleProps = Partial<IAccessCodeProps>;

export const Container = styled.div<AccessCodeStyleProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Title = styled.p<AccessCodeStyleProps>`
  margin: 0;
  font-size: 70px;
  font-weight: 300;
`;
export const Subtitle = styled.p<AccessCodeStyleProps>`
  white-space: pre-wrap;
  margin: 0;
  font-size: 14px;
`;
export const Form = styled.form<AccessCodeStyleProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  > * {
    margin: 12px;
  }
`;
export const Checkbox = styled.input<AccessCodeStyleProps>`
  margin: 4px;
`;
export const CheckboxLabel = styled.label<AccessCodeStyleProps>`
  > a {
    margin-left: 4px;
  }
`;

export const CheckboxInputGroup = styled.div<AccessCodeStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const CodeInputGroup = styled.div<AccessCodeStyleProps>`
  display: flex;
  flex: 1;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: space-between;

  border-radius: 50px;
  border: 1px solid grey;
  background-color: white;

  position: relative;

  height: 100%;
  width: 100%;
`;

export const codeTextInputStyle = `
  margin: 3px;
  outline: none;
  box-shadow: none;
  border-style: none;
  border: none;
  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

export const ErrorMessage = styled.div`
  margin: 5px;
  font-size: 12px;
  color: red;
  text-align: center;
`;
