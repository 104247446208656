import {LayoutModule, useShowInstructions} from '@backstage-components/base';
import {css} from '@emotion/react';
import {Fragment, useMemo, VFC} from 'react';
import {reactName, SchemaType, instructions} from './ModalDefinition';
import {useSubscription} from 'observable-hooks';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

export type ModalComponentDefinition = LayoutModule<'Modal', SchemaType>;
export const ModalComponent: VFC<ModalComponentDefinition> = (definition) => {
  const {props, id, slotRenderer: Component = () => <Fragment />} = definition;
  const {
    modalContentProps,
    modalOverlayProps,
    modalBodyProps,
    modalCloseButtonProps,
  } = props;
  // Props are spread so that null and undefined values are ignored
  const {styleAttr: bodyStyles, ...bodyProps} = {...modalBodyProps};
  const {styleAttr: contentStyles, ...contentProps} = {...modalContentProps};
  const {styleAttr: overlayStyles, ...overlayProps} = {...modalOverlayProps};
  const {
    hideCloseButton,
    styleAttr: closeStyles,
    ...closeProps
  } = {...modalCloseButtonProps};

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {observable, broadcast} = useShowInstructions(instructions, definition);
  useSubscription(observable, (inst) => {
    if (inst.type === `${reactName}:open`) {
      onOpen();
      broadcast({type: `${reactName}:on-open`, meta: {}});
    }

    if (inst.type === `${reactName}:close`) {
      onClose();
      broadcast({type: `${reactName}:on-close`, meta: {}});
    }
  });

  const renderedChildren = useMemo(() => {
    const {items, ...children} = definition.slots ?? {items: []};
    const components = Object.values(children)
      .flatMap((element) => {
        if (Array.isArray(element)) {
          return element;
        } else if (typeof element !== 'undefined') {
          return [element];
        } else {
          return [];
        }
      })
      .concat(items ?? []);
    const itemsLength = 100 / components.length;
    return components.map((component) => {
      const enableFlexShorthand =
        'autoLayout' in props && props.autoLayout === true;
      const flexShorthand = enableFlexShorthand
        ? `flex: 1 1 ${itemsLength}%;`
        : '';
      const componentStyle = `${component.style + ';' || ''} ${flexShorthand}`;
      return (
        <Component
          key={`${component.path.join(':')}:${component.mid}`}
          {...component}
          style={componentStyle}
        />
      );
    });
  }, [Component, definition.slots, props]);

  return (
    <Modal
      id={id}
      {...props}
      isOpen={isOpen}
      onClose={() => broadcast({type: `${reactName}:close`, meta: {}})}
      data-testid="modal"
    >
      <ModalOverlay
        {...overlayProps}
        css={css`
          ${overlayStyles}
        `}
      />
      <ModalContent
        {...contentProps}
        css={css`
          ${contentStyles}
        `}
      >
        {!hideCloseButton && (
          <ModalCloseButton
            {...closeProps}
            css={css`
              ${closeStyles}
            `}
          />
        )}
        <ModalBody
          {...bodyProps}
          data-testid="modal-body"
          css={css`
            ${bodyStyles}
          `}
        >
          {renderedChildren}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
