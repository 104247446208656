import {
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  animationDescription,
  ComponentAdmin,
  OptionalString,
  SubscribesTo,
  PublishesTo,
} from '@backstage-components/base';

import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Button';
export const name = reactName;
export const description = reactName;

export const schema = Type.Object(
  {
    children: OptionalString('Button Label'),
    href: OptionalString('Link Url'),
    buttonColor: OptionalString('Button Color'),
    textColor: OptionalString('Text Color'),
    fontSize: OptionalString('Font Size'),
    borderColor: OptionalString('Border Color'),
    padding: OptionalString('Padding'),
    fontFamily: OptionalString('Font Family'),
    borderRadius: OptionalString('Border Radius'),
    borderWidth: OptionalString('Border Width'),
    borderStyle: OptionalString('Border Style'),
    width: OptionalString('Width'),
    animationStates,
    styleAttr,
  },
  {
    dependencies: {
      href: {
        properties: {
          isExternal: Type.Boolean({
            default: false,
            title: 'Open Link in New Window?',
          }),
        },
      },
    },
  }
);

export type SchemaType = Static<typeof schema> & {isExternal?: boolean};

export const uiSchema = {
  ...styleAttrUi,
  ...animationUi,
  'ui:order': ['href', 'isExternal', '*'],
};

export const defaultFieldData: SchemaType = {
  buttonColor: '#000000',
  textColor: '#FFFFFF',
  ...styleAttr,
};

export const instructions = Type.Union([
  SubscribesTo({
    topic: `${reactName}:click`,
    description: 'Click button (virtually)',
  }),
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-click`,
    description: 'Button has been clicked',
  }),
]);

// TODO: continue threading instruction definitions through the app so we can have type instruction type checking in components.

export const ComponentDefinition: ComponentAdmin = {
  id: '49615581-37a3-48ea-a547-fa4f7255e35e',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions,
};
