import {
  LayoutModule,
  useMotionOptions,
  useShowInstructions,
  scrollTo,
  applyTween,
  Orientation,
} from '@backstage-components/base';
import {css, cx} from '@emotion/css';
import React, {CSSProperties, Fragment, useMemo, useState, VFC} from 'react';
import {
  Align,
  instructions,
  Preset,
  reactName,
  SchemaType,
} from './StackLayoutDefinition';
import {useSubscription} from 'observable-hooks';
import {motion} from 'framer-motion';
export type StackLayoutDefinition = LayoutModule<typeof reactName, SchemaType>;

export const StackLayout: VFC<StackLayoutDefinition> = (definition) => {
  const {slotRenderer: Component = () => <Fragment />, props} = definition;

  const {animationStates, layout} = props;
  const [activeVariant, setActiveVariant] = useState<string | undefined>(
    undefined
  );
  const motionOptions = useMotionOptions(animationStates);

  const {preset, align, orientation} = layout || {
    orientation: 'vertical',
  };
  const layoutStyle = computeLayout(preset, align, orientation);

  const renderedChildren = useMemo(() => {
    const {items, ...children} = definition.slots ?? {items: []};
    const components = Object.values(children)
      .flatMap((element) => {
        if (Array.isArray(element)) {
          return element;
        } else if (typeof element !== 'undefined') {
          return [element];
        } else {
          return [];
        }
      })
      .concat(items ?? []);
    const itemsLength = 100 / components.length;
    return components.map((component) => {
      const enableFlexShorthand =
        'autoLayout' in props && props.autoLayout === true;
      const flexShorthand = enableFlexShorthand
        ? `flex: 1 1 ${itemsLength}%;`
        : '';
      const componentStyle = `${component.style + ';' || ''} ${flexShorthand}`;
      return (
        <Component
          key={`${component.path.join(':')}:${component.mid}`}
          {...component}
          style={componentStyle}
        />
      );
    });
  }, [Component, definition.slots, props]);

  const {observable} = useShowInstructions(instructions, definition);

  useSubscription(observable, (inst) => {
    if (inst.type === 'Stacked:animationState') {
      applyTween(inst, animationStates, setActiveVariant);
    } else if (inst.type === 'Stacked:scrollTo') {
      const {elementId, anchorElId, scrollX, scrollY} = inst.meta;
      scrollTo({elementId, anchorElId, scrollX, scrollY});
    }
  });

  const backgroundClassName = css`
    background-position: ${props.backgroundPosition || 'center'};
    background-repeat: ${props.backgroundRepeat || 'no-repeat'};
    background-size: ${props.backgroundSize || 'cover'};
    background-image: url(${props.backgroundImage});
    background-color: ${props.backgroundColor};
    background-attachment: ${props.backgroundAttachment};
  `;

  const layoutClassName = css`
    margin: ${props.margin || '0'};
    padding: ${props.padding || '0'};
    gap: ${props.gap || '0'};
  `;

  const heightClassName = css`
    ${props.height && `height: ${props.height}`};
    ${props.minHeight && `min-height: ${props.minHeight}`};
    ${props.maxHeight && `max-height: ${props.maxHeight}`};
  `;

  const styleClassName = css`
    ${definition.style}
    ${props.styleAttr}
  `;
  return (
    <motion.div
      id={definition.id}
      data-testid={props.displayName}
      data-display-name={props.displayName}
      className={cx(
        css({...layoutStyle}),
        backgroundClassName,
        layoutClassName,
        styleClassName,
        heightClassName,
        definition.mid
      )}
      {...motionOptions}
      animate={activeVariant}
    >
      {renderedChildren}
    </motion.div>
  );
};

/**
 * @param preset settings that will return a corresponding flex layout.
 * @param align sets the `align-items` property.
 * @param orientation used to calculate flex-direction.
 * @returns a flex layout setting based on one of either `preset`, `flexbox` or `orientation`, or else returns `undefined`.
 */
export const computeLayout = (
  preset: Preset | undefined,
  align: Align | undefined,
  orientation: Orientation
): CSSProperties | undefined => {
  const direction = orientation === 'horizontal' ? 'row' : 'column';
  if (preset) {
    if (preset === 'start') {
      return {
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'flex-start',
        alignItems: align,
      };
    } else if (preset === 'end') {
      return {
        display: 'flex',
        flexDirection: direction,
        justifyContent: 'flex-end',
        alignItems: align,
      };
    } else {
      return {
        display: 'flex',
        flexDirection: direction,
        justifyContent: preset,
        alignItems: align,
      };
    }
  }
  if (orientation === 'vertical') {
    return {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: align,
    };
  } else if (orientation === 'horizontal') {
    return {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: align,
    };
  } else {
    return undefined;
  }
};
