import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {storage} from '@backstage-components/base';
import {
  assign,
  createMachine,
  send,
  EventObject,
  InvokeCreator,
  DoneInvokeEvent,
} from 'xstate';
import {GetAttendeeQuery, VerifyAccessCodeMutation} from './gql';
import type {
  GetAttendee,
  GetAttendeeVariables,
  VerifyAccessCode,
  VerifyAccessCodeVariables,
} from './gql';

const attendeeIdKey = (showId: string): string =>
  `lcd-ac/${showId.slice(0, 8)}/attendeeId`;
const attendeeTokenKey = (showId: string): string =>
  `lcd-ac/${showId.slice(0, 8)}/streamToken`;
const sessionTokenKey = (showId: string): string =>
  `lcd-ac/${showId.slice(0, 8)}/access-token`;
const accessCodeIdKey = (showId: string): string =>
  `lcd-ac/${showId.slice(0, 8)}/accessCodeId`;

const initialInvoke: InvokeCreator<Context, Action, PerformResult> = (
  context
) => {
  const initialAttendeeId = storage.getItem(attendeeIdKey(context.showId));
  const initialAccessCodeId = storage.getItem(accessCodeIdKey(context.showId));
  if (initialAttendeeId) {
    return performFetch(context, initialAttendeeId, initialAccessCodeId);
  } else {
    return Promise.reject('No stored attendee');
  }
};

const pendingInvoke: InvokeCreator<Context, Action, PerformResult> = (
  context,
  event
) => {
  if (event.type === 'FETCH') {
    const accessCodeId = storage.getItem(accessCodeIdKey(context.showId));
    return performFetch(context, event.meta.attendeeId, accessCodeId);
  } else if (event.type === 'VERIFY') {
    return performVerify(context, event.meta.showId, event.meta.accessCode);
  } else {
    throw new Error(`Unknown event type ${event.type}`);
  }
};

export const ContainerMachine = createMachine<Context, Action, Typestate>(
  {
    id: 'AttendeeContainer',
    initial: 'init',
    states: {
      init: {
        invoke: {
          id: 'initialize',
          src: initialInvoke,
          onDone: {
            actions: [
              send((context, event: DoneInvokeEvent<PerformResult>) => {
                const action: Action = {
                  type: 'FETCH_SUCCESS',
                  meta: {attendee: event.data},
                };
                return action;
              }),
            ],
          },
          onError: {
            target: 'idle',
          },
        },
        on: {
          FETCH_SUCCESS: {
            target: 'success',
            actions: ['updateAttendee'],
          },
        },
      },
      idle: {
        on: {
          FETCH: {
            target: 'pending',
            actions: [assign({about: (_c): undefined => undefined})],
          },
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
      pending: {
        invoke: {
          id: 'perform',
          src: pendingInvoke,
          onDone: {
            actions: [
              send((context, event: DoneInvokeEvent<PerformResult>) => {
                const action: Action = {
                  type: 'FETCH_SUCCESS',
                  meta: {attendee: event.data},
                };
                return action;
              }),
            ],
          },
          onError: {
            actions: [
              send(() => {
                const action: Action = {
                  type: 'FETCH_FAILURE',
                  meta: {},
                };
                return action;
              }),
            ],
          },
        },
        on: {
          FETCH_SUCCESS: {
            target: 'success',
            actions: ['updateAttendee'],
          },
          FETCH_FAILURE: {
            target: 'failure',
          },
        },
      },
      success: {
        on: {
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
      failure: {
        entry: ['clearAttendeeId'],
        on: {
          RESET: 'idle',
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
    },
  },
  {
    actions: {
      clearAttendeeId: assign((context, event) => {
        if (event.type === 'FETCH_FAILURE') {
          // Clear from local storage
          storage.removeItem(attendeeIdKey(context.showId));
          storage.removeItem(attendeeTokenKey(context.showId));
          // Return `BaseContext`
          return {about: context.about, client: context.client};
        } else {
          return context;
        }
      }),
      updateAttendee: assign((context, event) => {
        if (event.type === 'FETCH_SUCCESS') {
          const attendee = event.meta.attendee;
          const accessCodeId = event.meta.attendee.accessCodeId;
          const token = attendee.chatTokens.find(
            (token) => token.token.length > 0
          )?.token;
          const sessionToken = event.meta.attendee.sessionToken;
          // Set attendee id in local storage
          storage.setItem(attendeeIdKey(context.showId), attendee.id);
          // Set token in local storage
          if (typeof token === 'string') {
            storage.setItem(attendeeTokenKey(context.showId), token);
          }
          // Set session token in local storage
          if (typeof sessionToken === 'string') {
            storage.setItem(sessionTokenKey(context.showId), sessionToken);
          }
          // Set access code id in local storage
          if (typeof accessCodeId === 'string') {
            storage.setItem(accessCodeIdKey(context.showId), accessCodeId);
          }

          return {
            about: context.about,
            attendee,
            token,
            accessCodeId,
          };
        } else {
          return context;
        }
      }),
    },
  }
);

async function performFetch(
  context: BaseContext,
  attendeeId: string,
  accessCodeId: string | null = null
): Promise<Attendee> {
  return context.client
    .query<GetAttendee, GetAttendeeVariables>({
      query: GetAttendeeQuery,
      variables: {attendeeId, accessCodeId},
      context: {showId: context.showId},
    })
    .then((result) => {
      if (typeof result.error !== 'undefined') {
        throw result.error;
      } else if (typeof result.errors !== 'undefined') {
        throw new Error(result.errors.map((e) => e.message).join('  '));
      } else {
        return {
          ...result.data.attendeeById,
          attendeeTags: result.data.attendeeById.accessCodes.flatMap(
            (code) => code.attendeeTags
          ),
        };
      }
    });
}

async function performVerify(
  context: BaseContext,
  showId: string,
  accessCode: string
): Promise<Attendee> {
  return context.client
    .mutate<VerifyAccessCode, VerifyAccessCodeVariables>({
      mutation: VerifyAccessCodeMutation,
      variables: {showId, accessCode},
      context: {showId: context.showId},
    })
    .then((result) => {
      if (typeof result.errors !== 'undefined') {
        throw new Error(result.errors.map((e) => e.message).join('  '));
      } else if (
        typeof result.data?.verifyAccessCode.sessionToken === 'undefined' ||
        typeof result.data?.verifyAccessCode.attendee === 'undefined' ||
        result.data.verifyAccessCode.sessionToken === null ||
        result.data.verifyAccessCode.attendee === null
      ) {
        throw new Error('No data from verify.');
      } else {
        return {
          ...result.data.verifyAccessCode.attendee,
          sessionToken: result.data.verifyAccessCode.sessionToken,
          attendeeTags: result.data.verifyAccessCode.attendeeTags,
          accessCodeId: result.data.verifyAccessCode.id,
        };
      }
    });
}

type PerformResult =
  | Awaited<ReturnType<typeof performFetch>>
  | Awaited<ReturnType<typeof performVerify>>;

interface BaseContext {
  about?: string;
  client: ApolloClient<NormalizedCacheObject>;
  showId: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
  token?: string;
  sessionToken?: string;
  accessCodeId?: string;
}

type Context = SuccessContext | BaseContext;

type Action =
  | Event<'RESET'>
  | Event<'FETCH', {attendeeId: string}>
  | Event<'VERIFY', {about?: string; showId: string; accessCode: string}>
  | Event<'FETCH_FAILURE'>
  | Event<'FETCH_SUCCESS', {attendee: Attendee}>;

type Typestate =
  | {value: 'init'; context: BaseContext}
  | {value: 'idle'; context: BaseContext}
  | {value: 'pending'; context: BaseContext}
  | {value: 'success'; context: SuccessContext}
  | {value: 'failure'; context: BaseContext};

/**
 * An `Event` with a specific shape of data (`meta` key) and `type`.
 */
interface Event<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>
> extends EventObject {
  /** @inheritdoc */
  type: Kind;
  /**
   * The shape of Data included with the event, if any.
   */
  meta: Data;
}

type Attendee = Omit<
  GetAttendee['attendeeById'],
  '__typename' | 'accessCodes'
> & {
  sessionToken?: string;
  accessCodeId?: string;
  attendeeTags: string[];
};
