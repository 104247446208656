import {storage} from '../../../helpers';
import {NodeInstanceData} from '../../../types';
import {FlowExportObject} from './reactFlow.types';

type OverallNodeData = {
  id?: string;
  tabs: NodeTab[];
};
type NodeTab = {
  name: string;
  id: string;
  data: FlowExportObject<NodeInstanceData>;
};

export const getData = (): OverallNodeData => {
  if (typeof storage === 'undefined') {
    return {tabs: []};
  }
  return JSON.parse(storage.getItem('flows') || '{"tabs": []}');
};
