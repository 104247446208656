import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {useShowInstructions} from '@backstage-components/base';
import {AccessCodeInstructionSchema} from '@backstage-components/access-code';
import {useMachine} from '@xstate/react';
import {useSubscription} from 'observable-hooks';
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import {ContainerMachine} from './attendee-container-machine';

interface AttendeeContextBaseValue {
  setAttendeeId: (id: string) => void;
  verifyAccessCode: (accessCode: string) => void;
}

interface AttendeeContextAttendeeValue extends AttendeeContextBaseValue {
  attendeeId: string;
  attendeeName: string;
  attendeeEmail: string | null;
  token?: string;
  attendeeTags: string[];
}

type AttendeeContextValue =
  | AttendeeContextBaseValue
  | AttendeeContextAttendeeValue;

interface AttendeeProviderProps<ApolloCache = NormalizedCacheObject> {
  client: ApolloClient<ApolloCache>;
  attendeeId?: string;
  showId: string;
}

/**
 * @private exported for tests
 */
export const AttendeeContainer = createContext<
  AttendeeContextValue | undefined
>(undefined);
AttendeeContainer.displayName = 'AttendeeContainer';

/**
 * Context `Provider` to create and hold an attendee record.
 */
export const AttendeeProvider: FC<AttendeeProviderProps> = (props) => {
  const {client, showId} = props;
  const [state, dispatch] = useMachine(ContainerMachine, {
    context: {client, showId},
  });
  const attendee: Attendee | undefined = useMemo(() => {
    if (state.matches('success')) {
      return {
        id: state.context.attendee.id,
        name: state.context.attendee.name,
        email: state.context.attendee.email,
        chatTokens: state.context.attendee.chatTokens.filter(
          (token) => token.token.length > 0
        ),
        attendeeTags: state.context.attendee.attendeeTags,
      };
    } else {
      return undefined;
    }
  }, [state]);
  const setAttendeeId = useCallback(
    (attendeeId: string): void => {
      if (state.matches('idle')) {
        dispatch({type: 'FETCH', meta: {attendeeId}});
      } else {
        throw new Error(`Can not verify, machine is ${state.value}`);
      }
    },
    [state, dispatch]
  );
  const verifyAccessCode = useCallback(
    (accessCode: string): void => {
      if (state.matches('idle')) {
        dispatch({type: 'VERIFY', meta: {accessCode, showId}});
      } else {
        throw new Error(`Can not verify, machine is ${state.value}`);
      }
    },
    [state, dispatch, showId]
  );
  // Listen for broadcasts from the `AccessCode` component, when a verify
  // request happens forward it to the state machine
  const {observable, broadcast} = useShowInstructions(
    AccessCodeInstructionSchema
  );
  useSubscription(observable, {
    next: (instruction) => {
      if (
        instruction.type === 'AccessCode:verify' &&
        instruction.meta.showId !== showId
      ) {
        broadcast({
          type: 'AccessCode:failure',
          meta: {
            about: instruction.meta.about ?? undefined,
            reason: `ShowId mismatch expected: ${showId}, got: ${instruction.meta.showId}`,
          },
        });
      } else if (instruction.type === 'AccessCode:verify') {
        dispatch({
          type: 'VERIFY',
          meta: {
            about: instruction.meta.about,
            accessCode: instruction.meta.accessCode,
            showId,
          },
        });
      }
    },
  });
  // When the state machine transitions, if `about` is set in context then
  // broadcast an instruction indicating success or failure.
  useEffect(() => {
    if (state.matches('failure') && state.context.about) {
      broadcast({
        type: 'AccessCode:failure',
        meta: {about: state.context.about},
      });
    } else if (state.matches('success') && state.context.about) {
      broadcast({
        type: 'AccessCode:success',
        meta: {about: state.context.about, attendee: state.context.attendee},
      });
    }
  }, [broadcast, state]);

  const value: AttendeeContextValue = useMemo(() => {
    const baseContextValue: AttendeeContextBaseValue = {
      setAttendeeId,
      verifyAccessCode,
    };
    if (attendee) {
      return Object.assign(
        {
          attendeeId: attendee.id,
          attendeeName: attendee.name,
          attendeeEmail: attendee.email,
          token:
            attendee.chatTokens.length > 0
              ? attendee.chatTokens[0].token
              : undefined,
          attendeeTags: attendee.attendeeTags ?? [],
        },
        baseContextValue
      );
    } else {
      return baseContextValue;
    }
  }, [attendee, setAttendeeId, verifyAccessCode]);
  return <AttendeeContainer.Provider value={value} children={props.children} />;
};

/**
 * Gives access to the attendee with `attendeeId` if it exists, or the currently
 * authenticated attendee.
 * @param attendeeId of the attendee record to retrieve.
 * @returns the attendee record if it exists, `null` if it does not.
 */
export const useAttendee = (attendeeId?: string): Attendee | null => {
  const context = useContext(AttendeeContainer);
  if (context === undefined) {
    throw new Error('useAttendee must be used within a AttendeeProvider');
  }
  if ('attendeeId' in context) {
    const {attendeeId: currentId} = context;
    if (
      (typeof attendeeId === 'string' && attendeeId === currentId) ||
      typeof attendeeId === 'undefined'
    ) {
      return attendeeFromContext(context);
    } else {
      // `attendeeId` was passed but didn't match current attendee, reset id
      context.setAttendeeId(attendeeId);
      return null;
    }
  } else if (typeof attendeeId === 'string') {
    // `attendeeId` was passed but there is no current attendee, set id
    context.setAttendeeId(attendeeId);
    return null;
  } else {
    // No current attendee and no `attendeeId` passed
    return null;
  }
};

/**
 * Gives access to the already verified attendee if it exists, or requests
 * verification with a given `accessCode`. When called without `accessCode`
 * only provides an existing attendee.
 * @param accesscode of an attendee to verify.
 * @returns the attendee record if it exists, `null` if it does not.
 */
export const useVerifiedAttendee = (accessCode?: string): Attendee | null => {
  const context = useContext(AttendeeContainer);
  if (context === undefined) {
    throw new Error(
      'useVerifiedAttendee must be used within a AttendeeProvider'
    );
  }
  if ('attendeeId' in context) {
    return attendeeFromContext(context);
  } else if (typeof accessCode === 'string') {
    context.verifyAccessCode(accessCode);
    return null;
  } else {
    // no current attendee and no `accessCode` provided
    return null;
  }
};

function attendeeFromContext(context: AttendeeContextValue): Attendee | null {
  if ('attendeeId' in context) {
    return {
      id: context.attendeeId,
      name: context.attendeeName,
      email: context.attendeeEmail ?? null,
      chatTokens:
        typeof context.token === 'string' ? [{token: context.token}] : [],
      attendeeTags: context.attendeeTags,
    };
  } else {
    return null;
  }
}

interface Attendee {
  id: string;
  name: string;
  email: string | null;
  chatTokens: ChatToken[];
  attendeeTags: string[];
}

interface ChatToken {
  token: string;
}
