import {StaticObject, TProperties} from '@sinclair/typebox';

export const addStyles = <T extends TProperties>(
  props: StaticObject<T>,
  cssProps: string[]
): string => {
  return Object.entries(props)
    .filter((entry): entry is [string, string] => {
      const [key, val] = entry;
      return cssProps.includes(key) && typeof val === 'string';
    })
    .map(([key, val]) => {
      switch (key) {
        case 'background-image':
          return `${key}:url('${val}');`;
        default:
          return `${key}:${val};`;
      }
    })
    .join('\n');
};

const RGB_HEX_REGEX = /^#([A-Fa-f0-9]{3}){1,2}/;

/**
 * Extract red, green, and blue values from an RGB hex string.
 * @param hex string containing RGB color value.
 * @returns an object with the rgb values.
 * @throws `Error` if `hex` is not well formed.
 *
 * @private exported for tests
 */
export const hexToRgb = (hex: string): {r: number; g: number; b: number} => {
  if (!RGB_HEX_REGEX.test(hex)) {
    throw new Error(`${hex} does not represent an RGB color.`);
  }
  let color = hex.substring(1).split('');
  if (color.length === 3) {
    color = [color[0], color[0], color[1], color[1], color[2], color[2]];
  }
  const hexRep = parseInt(`0x${color.join('')}`, 16);
  return {
    r: (hexRep >> 16) & 0xff,
    g: (hexRep >> 8) & 0xff,
    b: hexRep & 0xff,
  };
};

/**
 * Create an RGBA color string from `hex` with the given `alpha` value.
 * @param hex string containing RGB color value.
 * @param alpha value to use for opacity.
 * @returns the formatted "rgba()" value.
 * @throws `Error` if `hex` is not well formed.
 * @throws `Error` if `alpha` is not in the range [0, 1]
 */
export const hexToRGBA = (hex: string, alpha: number): string => {
  if (alpha < 0 || alpha > 1) {
    throw new Error(`${alpha} is not a valid alpha value, in range [0, 1]`);
  }
  const {r, g, b} = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

/**
 * Compute relative luminance from an RGB value.
 * @param hex string containing RGB color value.
 * @returns relative luminance of the color represented by `hex`.
 * @see https://en.wikipedia.org/wiki/Relative_luminance
 * @see https://stackoverflow.com/a/596243
 */
export const hexToLuma = (hex: string): number => {
  const {r, g, b} = hexToRgb(hex);
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return Math.round(luma);
};
