import {
  styleAttr,
  styleAttrUi,
  animationStates,
  animationUi,
  ComponentAdmin,
  DeriveInstructionType,
  OptionalString,
  SubscribesTo,
  PublishesTo,
  animationDescription,
} from '@backstage-components/base';
import {schema as buttonSchema} from '@backstage-components/button';
import {schema as richTextSchema} from '@backstage-components/rich-text';
import {schema as textInputSchema} from '@backstage-components/text-input';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'AccessCode';
export const name = reactName;
export const description = reactName;

export const schema = Type.Object({
  title: OptionalString({title: 'Title'}),
  subtitle: OptionalString({title: 'Subtitle'}),
  accessCodeLength: Type.Optional(Type.Number({title: 'Access Code Length'})),
  accessCodeErrorMessage: OptionalString({
    title: 'Error message for wrong access code',
    default: 'Incorrect access code provided',
  }),
  showResendLink: Type.Optional(
    Type.Boolean({title: 'Show resend link?', default: true})
  ),
  showTermsCheckbox: Type.Optional(
    Type.Boolean({title: 'Show terms and conditions checkbox?', default: true})
  ),
  submitButtonProps: Type.Optional(
    Type.Omit(
      {
        ...buttonSchema,
        title: 'Submit Button Props',
      },
      [
        'href',
        'borderRadius',
        'borderColor',
        'borderWidth',
        'borderStyle',
        'padding',
        'fontSize',
        'fontFamily',
        'animationStates',
        'styleAttr',
      ]
    )
  ),
  codeTextInputProps: Type.Optional(
    Type.Omit(
      {
        ...textInputSchema,
        title: 'Code Input Props',
      },
      ['name', 'inputType']
    )
  ),
  // props to replicate the terms link/checkbox in accessCode
  termsLinkProps: Type.Optional(
    Type.Partial({
      ...richTextSchema,
      title: 'Checkbox Label (Rich Text)',
    })
  ),
  // props to replicate the resend code button in accessCode
  resendButtonProps: Type.Optional(
    Type.Omit(
      {
        ...buttonSchema,
        title: 'Resend Button Props',
      },
      [
        'href',
        'buttonColor',
        'borderColor',
        'borderRadius',
        'borderStyle',
        'borderWidth',
        'fontFamily',
        'fontSize',
        'padding',
        'animationStates',
        'styleAttr',
      ]
    )
  ),
  animationStates,
  styleAttr,
});

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  subtitle: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 5,
    },
  },
  ...styleAttrUi,
  ...animationUi,
};

export const defaultFieldData: SchemaType = {};

export const AccessCodeInstructionSchema = Type.Union([
  SubscribesTo({
    topic: `${reactName}:success`,
    description: 'Access code was successfully verified',
    meta: {
      attendee: Type.Object({
        id: Type.String({
          description: 'Unique identifier for the verified Attendee',
        }),
        name: Type.String({
          description: 'Name of the attendee, if known',
        }),
        email: Type.Union([Type.Null(), Type.String()], {
          description: 'Attendee email address if known, null otherwise',
        }),
        chatTokens: Type.Array(
          Type.Object({
            token: Type.String({
              description: 'Token used to authenticate with getstream API',
            }),
          })
        ),
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:failure`,
    description: 'Access code could not be verified',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  SubscribesTo({
    topic: `${reactName}:reset`,
    description:
      'Resets the access code component (local only) clearing the form',
  }),
  SubscribesTo({
    topic: `${reactName}:animationState`,
    description: animationDescription,
    meta: {
      stateName: Type.String(),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-failure`,
    description: 'Indicates an unsuccessful verification has occurred.',
    meta: {
      reason: OptionalString({
        description: 'Indicates the reason the code could not be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was unable to be verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:on-success`,
    description: 'Indicates a successful verification has occurred.',
    meta: {
      attendeeId: Type.String({
        description: 'Unique identifier for the verified Attendee',
      }),
      attendeeName: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee name if available, null otherwise',
      }),
      attendeeEmail: Type.Union([Type.Null(), Type.String()], {
        description: 'Attendee email address if available, null otherwise',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which Attendee was verified.',
      }),
    },
  }),
  PublishesTo({
    topic: `${reactName}:verify`,
    description: 'Requests the given access code be verified for the show',
    meta: {
      accessCode: Type.String({
        description: 'Show access code to be verified',
      }),
      showId: Type.String({
        description:
          'Unique identifier for the show against which access code will be checked',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
]);

export type AccessCodeInstruction = DeriveInstructionType<
  typeof AccessCodeInstructionSchema
>;

export const ComponentDefinition: ComponentAdmin = {
  id: 'e3dbcc42-2fbe-44ed-97b9-95d795eceac5',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
  instructions: AccessCodeInstructionSchema,
};
