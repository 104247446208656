import {
  styleAttr,
  styleAttrUi,
  ComponentAdmin,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'RichText';
export const name = reactName;
export const description = reactName;

export const schema = Type.Object({
  content: Type.String({title: 'Content Markup'}),
  styleAttr,
});

export type SchemaType = Static<typeof schema>;

export const uiSchema = {
  content: {
    'ui:widget': 'textarea',
    'ui:options': {
      rows: 5,
    },
  },
  ...styleAttrUi,
};

export const defaultFieldData: SchemaType = {
  content: '',
};

export const ComponentDefinition: ComponentAdmin = {
  id: 'c674b76d-f7e3-4f55-9003-29c66ab28847',
  reactName,
  name,
  slug: reactName,
  description,
  version: 1,
  defaultFieldData,
  slotConfiguration: {},
  schema,
  uiSchema,
};
