import {Type, TOptional, TObject, TNumber, TString} from '@sinclair/typebox';
import {RegisterOptions} from 'react-hook-form';

type RuleSchema = TObject<{
  value: TOptional<TNumber>;
  message: TOptional<TString>;
}>;

const ruleSchema = (title: string): RuleSchema =>
  Type.Partial(
    Type.Object(
      {
        value: Type.Number({title: 'Value'}),
        message: Type.String({title: 'Rule Error Message'}),
      },
      {title}
    )
  );

const regexOptionSchema = Type.Partial(
  Type.Object(
    {
      value: Type.String({title: 'Regex Pattern', format: 'regex'}),
      message: Type.String({title: 'Rule Error Message'}),
    },
    {title: 'Regex Pattern'}
  )
);

const requiredOptionSchema = Type.Union(
  [
    Type.Boolean({title: 'Is this field required?'}),
    Type.String({title: 'Custom Required Message (required = true)'}),
  ],
  {title: 'Required?'}
);

export const numberValidationOptionGroupSchema = Type.Object(
  {
    required: requiredOptionSchema,
    min: Type.Optional(ruleSchema('Min Value')),
    max: Type.Optional(ruleSchema('Max Value')),
    pattern: Type.Optional(regexOptionSchema),
  },
  {title: 'Number Validation Options'}
);

export const textValidationOptionGroupSchema = Type.Object(
  {
    required: requiredOptionSchema,
    minLength: Type.Optional(ruleSchema('Min Length')),
    maxLength: Type.Optional(ruleSchema('Max Length')),
    pattern: Type.Optional(regexOptionSchema),
  },
  {title: 'Text Validation Options'}
);

type NumberValidationOptions = Pick<
  RegisterOptions,
  'required' | 'min' | 'max' | 'pattern'
>;
type TextValidationOptions = Pick<
  RegisterOptions,
  'required' | 'minLength' | 'maxLength' | 'pattern'
>;
export type ValidationOptions = TextValidationOptions | NumberValidationOptions;

export const InputTypes = ['text', 'number'] as const;
