import {
  CustomOptions,
  ObjectOptions,
  Static,
  StringFormatOption,
  StringOptions,
  TAny,
  TArray,
  TBoolean,
  TEnum,
  TEnumKey,
  TInteger,
  TIntersect,
  TKey,
  TKeyOf,
  TLiteral,
  TNull,
  TNumber,
  TObject,
  TOptional,
  TProperties,
  TRecord,
  TRecordKey,
  TRef,
  TString,
  TTuple,
  TUndefined,
  TUnion,
  TUnknown,
  TValue,
  TVoid,
  Type,
} from '@sinclair/typebox';
import {RenameKey} from '../types';
import {FLOW_IGNORE} from './instruction-helpers';

export function OptionalString(title: string): TOptional<TString>;

export function OptionalString(
  options: StringOptions<StringFormatOption>
): TOptional<TString>;

export function OptionalString(
  options: string | StringOptions<StringFormatOption>
): TOptional<TString> {
  if (typeof options === 'string') {
    return Type.Optional(Type.String({title: options}));
  } else {
    return Type.Optional(Type.String(options));
  }
}

export const Nullable = <T extends TypeBoxSchema>(
  type: T
): TUnion<[T, TNull]> => Type.Union([type, Type.Null()]);

/**
 * Drop the `which` key and rename the `topic` key for the given
 * `ParticipantType`. If `ParticipantType` is not an extension of
 * `ParticipantOutput` the type will resolve to `never`.
 *
 * @example
 * // `FooI` resolves to {type: 'foo', meta: {about?: string}}
 * const Foo = Participate('publish')({
 *   topic: 'foo',
 * });
 * type FooI = AsInstruction<typeof Foo>;
 * @example
 * // `BarI` resolves to {type: 'bar', meta: {about?: string, biz: string}}
 * const Bar = Participate('subscribe')({
 *   topic: 'bar',
 *   meta: {
 *     biz: Type.String(),
 *   },
 * });
 * type BarI = AsInstruction<typeof Bar>;
 */
export type AsInstruction<ParticipantType> =
  ParticipantType extends InstructionSchema
    ? Omit<RenameKey<Static<ParticipantType>, 'topic', 'type'>, 'which'>
    : never;

/**
 * Get the type of the broadcast function from a known `TUnion` schema
 * definition.
 */
export type BroadcastFn<T> = T extends TUnion<infer Schema>
  ? Schema extends InstructionSchema[]
    ? Broadcaster<Schema>
    : never
  : never;

export type Broadcaster<Schema extends InstructionSchema[]> = (
  instruction: DeriveInstruction<Schema>
) => void;

/**
 * Drop the `which` key and rename the `topic` key for each of the
 * `ParticipantOutput` types in the Union. This must be done from the Array
 * inferred from `TUnion` because the `Pick` implicit in `Omit` would break the
 * type narrowing capabilities of `type` if applied to the result rather than to
 * each member via `[number]`.
 * @example
 * const Instructions = Type.Union([
 *   Participate('publish')({
 *     topic: 'foo',
 *   }),
 *   Participate('subscribe')({
 *     topic: 'bar',
 *     meta: {
 *       biz: Type.String(),
 *     },
 *   }),
 * ]);
 * type Action = DeriveInstructionType<typeof Instructions>;
 * // Type error
 * const invalidFoo: Action = {
 *   type: 'foo',
 *   meta: {biz: ''},
 * };
 */
export type DeriveInstructionType<Inst extends TypeBoxSchema> =
  Inst extends TUnion<infer Schema>
    ? Schema extends InstructionSchema[]
      ? DeriveInstruction<Schema>
      : never
    : never;

/**
 * Drop the `which` key and rename the `topic` key for each of the given
 * `InstructionSchema` types.
 * @example
 * const Instructions = [
 *   Participate('publish')({
 *     topic: 'foo',
 *   }),
 *   Participate('subscribe')({
 *     topic: 'bar',
 *     meta: {
 *       biz: Type.String(),
 *     },
 *   }),
 * ];
 * type Action = DeriveInstruction<typeof Instructions>;
 * // Type error
 * const invalidFoo: Action = {
 *   type: 'foo',
 *   meta: {biz: ''},
 * };
 */
export type DeriveInstruction<Schema extends InstructionSchema[]> =
  AsInstruction<Schema[number]>;

export type WhichTypes = 'subscribe' | 'publish';

export type ParticipantInputSimple<Channel extends string> = {
  /** with which we are interacting. */
  topic: Channel;
  /**
   * to be associated with the `meta` shape of the schema.
   * @default ''
   */
  description?: string;
  /** to use with the returned schema object. */
  options?: ObjectOptions & FlowEditorOptions;
};

export type ParticipantInput<
  Channel extends string,
  Props extends TProperties
> = ParticipantInputSimple<Channel> & {
  /** to include in the `meta` shape of the schema. NOTE: already includes `about` */
  meta: Props;
};

type AboutMeta = {about: TOptional<TString>};

interface FlowEditorOptions {
  /**
   * If `true` the instruction node will not appear in the site-builder flow
   * editor.
   */
  [FLOW_IGNORE]?: boolean;
}

export const ModuleIdentifiersSchema = Type.Optional(
  Type.Object({
    id: Type.String({
      title: 'ID',
      description: `Id that will be used in the DOM to identify the module.`,
    }),
    mid: Type.String({
      title: 'Module Id',
      description: `Identifier for the module "glue" attaching cid to the module's parent`,
    }),
    cid: Type.String({
      title: 'Core Id',
      description:
        'Identifier for the core holding configuration for the attached component',
    }),
    gid: Nullable(
      Type.String({
        title: 'Group Id',
        description:
          'Identifier for the core to which the identified module belongs',
      })
    ),
    groupModuleId: Nullable(
      Type.String({
        title: 'Group Module Id',
        description:
          'Identifier for the group instance to which the identified module belongs',
      })
    ),
    path: Type.Array(Type.String(), {
      description: 'Path from page "root" to the identified module',
    }),
  })
);

export type ModuleIdentifiers = Static<typeof ModuleIdentifiersSchema>;

/**
 * Supplies the strict set of properties of ModuleIdentifiers in a specific
 * order so `JSON.stringify` is deterministic.
 * @param moduleIdentifiers ...or any object that implements `ModuleIdentifiers`
 * @returns ModuleIdentifiers
 */
export const extractModuleIdentifiers = <Input extends ModuleIdentifiers>(
  moduleIdentifiers: Input
): ModuleIdentifiers => {
  return {
    id: moduleIdentifiers.id,
    mid: moduleIdentifiers.mid,
    cid: moduleIdentifiers.cid,
    gid: moduleIdentifiers.gid ?? null,
    groupModuleId: moduleIdentifiers.groupModuleId ?? null,
    path: moduleIdentifiers.path?.slice(0) ?? [],
  };
};

/**
 * Generates an object with "dummy" values that quacks like `ModuleIdentifiers`.
 * @private exported for testing and for site-builder which doesn't run instructions.
 */
export const dummyModuleIdentifiers = (options?: {
  isGroup?: boolean;
}): ModuleIdentifiers => {
  return {
    id: 'id69999997b7b',
    mid: 'd6999999-7b7b-41a6-8f9e-a72e1ffe7bea',
    cid: 'c2999999-736d-4961-a883-ba17628dc8a7',
    gid: options?.isGroup ? '74999999-ad2b-41c0-b2ca-fdd1054136bf' : null,
    groupModuleId: options?.isGroup
      ? '85999999-ad2b-41c0-b2ca-fdd1054136bf'
      : null,
    path: [],
  };
};

export type ParticipantOutput<
  Channel extends string,
  Which extends WhichTypes,
  Props extends TypeBoxProperties | Record<string, never> = Record<
    string,
    never
  >
> = TypeBoxObject<{
  topic: TLiteral<Channel>;
  which: TLiteral<Which>;
  meta: Props extends Record<string, never>
    ? TypeBoxObject<AboutMeta>
    : TypeBoxObject<Props & AboutMeta>;
}>;

type ParticipateResult<
  Channel extends string,
  Which extends WhichTypes,
  Props extends TypeBoxProperties | Record<string, never>
> =
  | ParticipantOutput<Channel, Which>
  | ParticipantOutput<Channel, Which, Props>;

/**
 * Higher-order function for making SubscribesTo and PublishesTo. Internally, it uses an overloaded function
 * because with a single type signature, the TS type returned will be too permissive in what fields it accepts
 * on the `meta` object.
 * @param which value of subscribe|publish to describe how this component interacts with this Instruction
 * @returns a function that defines the shape of and Instruction, including importantly its `meta` field
 */
// The type signatures of the returned functions are explicit, allow inference
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Participate = <Which extends WhichTypes>(which: Which) => {
  /**
   * Creates a participant message definition where the `meta` field contains
   * only the `about` property.
   * @param obj with topic and description data.
   * @returns the typebox schema based on `obj`.
   */
  function p<Channel extends string>(
    obj: ParticipantInputSimple<Channel>
  ): ParticipantOutput<Channel, Which>;

  /**
   * Creates a participant message definition where the `meta` field contains
   * the `about` property and the `meta` definition from `obj`.
   * @param obj with topic, description, and a schema for `meta`.
   * @returns the typebox schema based on `obj`.
   */
  function p<Channel extends string, Props extends TypeBoxProperties>(
    obj: ParticipantInput<Channel, Props>
  ): ParticipantOutput<Channel, Which, Props>;

  function p<Channel extends string, Props extends TypeBoxProperties>(
    obj: ParticipantInputSimple<Channel> | ParticipantInput<Channel, Props>
  ): ParticipateResult<Channel, Which, Props> {
    const baseFields = {
      topic: Type.Literal(obj.topic),
      which: Type.Literal(which),
    };
    if ('meta' in obj) {
      return Type.Object(
        {
          ...baseFields,
          meta: Type.Object(
            {
              ...obj.meta,
              about: OptionalString({
                title: 'About',
                description:
                  'Identifies the module where the instruction originated.',
              }),
            },
            {description: obj.description}
          ),
        },
        obj.options
      );
    } else {
      return Type.Object(
        {
          ...baseFields,
          meta: Type.Object(
            {
              about: OptionalString('About'),
            },
            {description: obj.description}
          ),
        },
        obj.options
      );
    }
  }
  return p;
};

/**
 * Helper function to define the schema of an instruction the module "publishes" or "broadcasts".
 * @returns a schema object defining an instruction the component "publishes" or "broadcasts".
 */
export const PublishesTo = Participate('publish');

/**
 * Helper function to define the schema of an instruction to which the module "subscribes" or "receives".
 * @returns a schema object defining an instruction to which the module "subscribes" or "receives".
 */
export const SubscribesTo = Participate('subscribe');

export type InstructionSchema =
  | ReturnType<typeof PublishesTo>
  | ReturnType<typeof SubscribesTo>;

/**
 * Makes a string union in Typescript, which will be represented as a dropdown in RJSF forms via an enum representation in JSON Schema.
 * @param vals readonly array 🚨🚨🚨 MUST BE typed as literal values. Eg, `['a', 'b'] as const`
 * @param options optional CustomOptions object. Usually just use `title`
 * @returns Typebox type definition that translates to use of the `enum` key in JSON Schema
 */
export const StringEnum = <Member extends TValue & TKey>(
  vals: readonly Member[],
  options?: CustomOptions
): TKeyOf<Member[]> => {
  const obj: Record<Member, TLiteral<Member>> = Object.assign(
    {},
    ...vals.map((x) => ({[x]: Type.Literal(x)}))
  );

  return Type.KeyOf(Type.Object(obj), options);
};

/**
 * Union of types from `@sinclair/typebox` which extend `TSchema`.
 */
export type TypeBoxSchema =
  | TString
  | TNumber
  | TInteger
  | TBoolean
  | TNull
  | TUnknown
  | TAny
  | TUndefined
  | TVoid
  | TRef<TypeBoxSchema>
  | TKeyOf<TKey[]>
  | TIntersect<TypeBoxSchema[]>
  | TUnion<TypeBoxSchema[]>
  | TObject<{[key: string]: TypeBoxSchema}>
  | TTuple<TypeBoxSchema[]>
  | TRecord<TRecordKey, TypeBoxSchema>
  | TEnum<TEnumKey[]>
  | TLiteral<TValue>
  | TArray<TypeBoxSchema>;

/** Replacement of `TProperties` which extends the union of `TSchema` types. */
export type TypeBoxProperties = {[key: string]: TypeBoxSchema};

/** Replacement of `TObject` which uses the union of `TypeBoxProperties` rather
 * than `TProperties`.
 */
export type TypeBoxObject<T extends TypeBoxProperties> = TObject<T>;
