import {VFC, useEffect} from 'react';
import {css} from '@emotion/react';
import {
  ContentModule,
  extractModuleIdentifiers,
  ModuleProperties,
  useShowId,
  useShowInstructions,
} from '@backstage-components/base';
import {ButtonComponent, ButtonProps} from '@backstage-components/button';
import {
  RichTextComponent,
  RichTextProps,
} from '@backstage-components/rich-text';
import {
  TextInputProps,
  TextInputComponent,
} from '@backstage-components/text-input';
import {useMachine} from '@xstate/react';
import {useSubscription} from 'observable-hooks';
import {useForm, SubmitHandler} from 'react-hook-form';
import {SchemaType, AccessCodeInstructionSchema} from './AccessCodeDefinition';
import * as Styled from './AccessCode.styled';
import {AccessCodeMachine} from './AccessCodeMachine';

export const defaultAccessCodeProps: Required<
  Pick<
    IAccessCodeProps,
    | 'title'
    | 'subtitle'
    | 'termsLinkProps'
    | 'codeTextInputProps'
    | 'submitButtonProps'
    | 'resendButtonProps'
  >
> = {
  title: 'WELCOME',
  subtitle: 'Enter your one-time access code we sent to your email.',
  termsLinkProps: {
    content: 'I agree to the <a href="#" target="_blank">Terms and Policy</a>',
  },
  submitButtonProps: {
    borderRadius: '100px',
    htmlType: 'submit',
    children: 'SUBMIT',
  },
  codeTextInputProps: {
    name: 'accessCode',
    id: 'access-code',
    placeholder: 'Enter Passcode',
    inputType: 'text',
    validationOptions: {
      required: 'code cannot be empty',
      minLength: {
        value: 1,
        message: `your code should be at least a character`,
      },
    },
  },
  resendButtonProps: {
    buttonColor: 'transparent',
    children: 'Resend My Code',
    fontSize: '10px',
  },
};

type CodeFormValues = {
  accessCode: string;
};

export interface IAccessCodeProps extends ModuleProperties {
  title: string;
  subtitle?: string;
  accessCodeLength?: number;
  showResendLink?: boolean;
  submitButtonProps: ButtonProps;
  codeTextInputProps: TextInputProps;
  termsLinkProps?: RichTextProps;
  resendButtonProps?: ButtonProps;
  routeOnCodeAuthSuccess?: string;
}

export type AccessCodeComponentDefinition = ContentModule<
  'AccessCode',
  IAccessCodeProps & SchemaType
>;

export const AccessCodeComponent: VFC<AccessCodeComponentDefinition> = (
  definition
) => {
  const moduleIdentifiers = extractModuleIdentifiers(definition);

  const {id, mid, config} = definition;
  const {
    title,
    subtitle,
    codeTextInputProps,
    termsLinkProps,
    resendButtonProps,
    submitButtonProps,
    accessCodeLength,
    showResendLink = true,
    showTermsCheckbox = true,
    accessCodeErrorMessage = 'Incorrect access code provided',
  } = definition.props;

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const showId = useShowId();
  const {observable, broadcast} = useShowInstructions(
    AccessCodeInstructionSchema,
    definition
  );
  const [state, dispatch] = useMachine(AccessCodeMachine, {
    context: {broadcast, showId},
  });
  const {register, formState, handleSubmit, setError} = useForm({
    /**
     * @param mode defines when validation is triggered.
     * AccessCode is set at 'onSubmit'
     */
    mode: 'onSubmit',
  });
  const errors = formState.errors;

  useEffect(() => {
    if (state.matches('failure')) {
      setError('accessCode', {message: accessCodeErrorMessage});
    }
  }, [accessCodeErrorMessage, setError, state]);
  useSubscription(observable, {
    next: (instruction) => dispatch(instruction),
  });
  const onSubmit: SubmitHandler<CodeFormValues> = (data) => {
    // Send the instruction to the state machine, which will trigger the
    // broadcast
    dispatch({
      type: 'AccessCode:verify',
      meta: {accessCode: data.accessCode, showId},
    });
  };

  const validationOptions = {
    required: 'code cannot be empty',
    minLength: {
      value: accessCodeLength || 10,
      message: `your code should be at least ${
        accessCodeLength || 10
      } characters`,
    },
    maxLength: {
      value: accessCodeLength || 10,
      message: `your code should be no more than ${
        accessCodeLength || 10
      } characters`,
    },
  };

  return (
    <Styled.Container id={id} css={styles}>
      <Styled.Title className="access-code-title">
        {title || defaultAccessCodeProps.title}
      </Styled.Title>
      <Styled.Subtitle className="access-code-subtitle">
        {subtitle || defaultAccessCodeProps.subtitle}
      </Styled.Subtitle>

      <Styled.Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {termsLinkProps && showTermsCheckbox && (
          <Styled.CheckboxInputGroup className="access-code-checkbox-input-group">
            <Styled.Checkbox
              role="checkbox"
              className="access-code-checkbox-input"
              type="checkbox"
              {...register('terms', {
                required: 'please agree to the terms to continue',
              })}
            />
            <Styled.CheckboxLabel className="access-code-checkbox-label">
              <RichTextComponent
                config={config}
                {...moduleIdentifiers}
                mid={`${mid}-access-code-checkbox-label`}
                id={`${id}-access-code-checkbox-label`}
                component={'RichText'}
                props={{
                  content:
                    termsLinkProps.content ||
                    defaultAccessCodeProps.termsLinkProps?.content,
                }}
              />
            </Styled.CheckboxLabel>
          </Styled.CheckboxInputGroup>
        )}

        <Styled.CodeInputGroup className="access-code-text-input-group">
          <TextInputComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-text-input`}
            component={'TextInput'}
            props={{
              disabled: state.matches('pending'),
              parentRegister: register,
              ...codeTextInputProps,
              name: defaultAccessCodeProps.codeTextInputProps.name,
              inputType: defaultAccessCodeProps.codeTextInputProps.inputType,
              validationOptions: accessCodeLength
                ? validationOptions
                : defaultAccessCodeProps.codeTextInputProps.validationOptions,
              styleAttr: `${codeTextInputProps?.styleAttr}
              ${Styled.codeTextInputStyle}`,
            }}
          />
          {!('href' in defaultAccessCodeProps.submitButtonProps) && (
            <ButtonComponent
              config={config}
              {...moduleIdentifiers}
              mid={`${mid}-access-code-submit-button`}
              component={'Button'}
              props={{
                ...submitButtonProps,
                disabled: state.matches('pending'),
                children:
                  submitButtonProps?.children ||
                  defaultAccessCodeProps?.submitButtonProps?.children,
                borderRadius:
                  defaultAccessCodeProps.submitButtonProps?.borderRadius,
                htmlType: defaultAccessCodeProps.submitButtonProps.htmlType,
              }}
            />
          )}
        </Styled.CodeInputGroup>

        {(errors?.['terms'] || errors?.['accessCode']) && (
          <Styled.ErrorMessage
            data-testid="access-code-text-input-error-message"
            className="access-code-text-input-error-message"
          >
            {errors?.['terms']?.message ?? errors?.['accessCode']?.message}
          </Styled.ErrorMessage>
        )}
      </Styled.Form>
      {resendButtonProps && showResendLink && (
        <div className="resend-button-component">
          <ButtonComponent
            config={config}
            {...moduleIdentifiers}
            mid={`${mid}-access-code-resend-button`}
            component={'Button'}
            props={{
              onClick: () => {
                //TODO: implement logic to handle request to resend access code
                console.log('TODO: resend code');
              },
              ...resendButtonProps,
              buttonColor:
                defaultAccessCodeProps.resendButtonProps?.buttonColor,
              children:
                resendButtonProps.children ||
                defaultAccessCodeProps.resendButtonProps?.children,
              fontSize:
                resendButtonProps.fontSize ||
                defaultAccessCodeProps.resendButtonProps?.fontSize,
              styleAttr: `padding: 0;`,
            }}
          />
        </div>
      )}
    </Styled.Container>
  );
};
