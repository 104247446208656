import {FALLBACK_PAGE_PATH} from '@backstage-components/base';
import {RouterContainer} from '@backstage-components/router';
import {VFC, Fragment, useEffect, useMemo, SetStateAction} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {config} from './config';
import {AppContext} from './AppContext';
import {PageRoute} from './PageRoute';

import {ContactLcdFallback} from './components/ContactLcdFallback';
import {FourOhFourLcdFallback} from './components/FourOhFourLcdFallback';
import {type LocationDetails} from './hooks';
import {useShowId, usePageListings, useDomainName} from './hooks';

interface AppProps {
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}

const App: VFC<AppProps> = ({setIsLoading}) => {
  const {domainName, isPreview, showId: pathShowId} = useDomainName() ?? {};
  const {data, called, error, loading} = usePageListings(domainName);

  const isLoading = loading || !called || (!data && !error);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  const site = data?.site;
  const flow = site?.flows[0];
  //Verify show id in url
  const showId = useShowId(site);

  const {
    router: prefix,
    withShowId: prefixWithShowId,
    withoutShowId: prefixWithoutShowId,
  } = useMemo(
    () => getRoutePrefixes({domainName, isPreview, pathShowId, showId}),
    [domainName, isPreview, pathShowId, showId]
  );
  const domainRecord = site?.domains.find((d) => d.name === domainName);

  useEffect(() => {
    if (flow) {
      localStorage.setItem(
        'flows',
        JSON.stringify(Object.assign({id: flow.id}, flow.data), null, 2)
      );
    }
  }, [flow]);

  if (typeof domainName === 'undefined' || domainName === '') {
    // No domain found
    return (
      <AppContext appPages={[]} showId={undefined}>
        <ContactLcdFallback />
      </AppContext>
    );
  } else if (typeof site === 'undefined' && config.stage === 'developer') {
    // Site not found
    return (
      <AppContext appPages={[]} showId={undefined}>
        <FourOhFourLcdFallback
          message={`Site Not Found for Domain(${domainName})`}
        />
      </AppContext>
    );
  } else if (
    typeof domainRecord === 'undefined' &&
    config.stage === 'developer'
  ) {
    // No pages on domain
    return (
      <AppContext appPages={[]} showId={undefined}>
        <FourOhFourLcdFallback
          message={`No Pages found for Domain(${domainName})`}
        />
      </AppContext>
    );
  } else if (!isLoading) {
    // `Route` needs to be a direct child of `Switch`. `PageRoute` cannot
    // encapsulate `Route` so assemble the `Route` and `PageRoute` elements in
    // multiple passes
    const pages = site?.items ?? [];
    const FallbackComponent = pages
      .filter((item) => item.pathname === FALLBACK_PAGE_PATH)
      .map((item) => <PageRoute showId={showId} page={item} />)[0] ?? (
      <FourOhFourLcdFallback />
    );
    const pageRoutes = pages
      .filter((item) => item.pathname !== FALLBACK_PAGE_PATH)
      .map((item) => ({
        pathname: item.pathname,
        component: <PageRoute showId={showId} page={item} />,
      }));
    const appPages = pages.map((item) => ({
      pathname: item.pathname,
      structure: item.structure,
    }));
    return (
      <AppContext appPages={appPages} showId={showId}>
        <Router>
          <RouterContainer prefix={prefix}>
            {pageRoutes.map((pageRoute) => {
              const pathWithoutShow = `${prefixWithoutShowId}${pageRoute.pathname}`;
              return (
                <Route
                  key={pathWithoutShow}
                  path={pathWithoutShow}
                  element={pageRoute.component}
                />
              );
            })}
            {pageRoutes.map((pageRoute) => {
              const pathWithShow = `${prefixWithShowId}${pageRoute.pathname}`;
              return (
                <Route
                  key={pathWithShow}
                  path={pathWithShow}
                  element={pageRoute.component}
                />
              );
            })}
            <Route element={FallbackComponent} path="*" />
          </RouterContainer>
        </Router>
      </AppContext>
    );
  } else {
    return <Fragment />;
  }
};

export default App;

function getRoutePrefixes(options: RoutePrefixOptions): RoutePrefixes {
  const {domainName, isPreview, pathShowId, showId} = options;
  const prefix =
    isPreview && pathShowId === showId
      ? `/${domainName}/${pathShowId}` // is preview and showIds match, include both
      : isPreview && pathShowId !== showId
      ? `/${domainName}` // is preview and showIds don't match, just domain
      : !isPreview && pathShowId === showId
      ? `/${pathShowId}` // not preview and showIds match, just show id
      : ''; // not preview and show id doesn't match no prefix
  const isWithShowId = prefix.includes(`/${showId}`);
  const prefixWithoutShowId = isWithShowId
    ? prefix.replace(`/${showId}`, '')
    : prefix;
  const prefixWithShowId = isWithShowId ? prefix : `${prefix}/${showId}`;
  return {
    router: prefix,
    withShowId: prefixWithShowId,
    withoutShowId: prefixWithoutShowId,
  };
}

interface RoutePrefixOptions extends Partial<LocationDetails> {
  pathShowId?: string;
}

interface RoutePrefixes {
  /**
   * Prefix to use with the router, this matches the shape of the current URL
   * regardless of the presence of a show id in it.
   */
  router: string;
  /**
   * The `router` prefix explicitly _with_ the show id.
   */
  withShowId: string;
  /**
   * The `router` prefix explicitly _without_ the show id.
   */
  withoutShowId: string;
}
