import {lazy, Fragment, Suspense, VFC} from 'react';
import {ContentComponent} from '../types/dynamicComponent.types';

const componentList = {
  AccessCode: lazy(() =>
    import('@backstage-components/access-code').then(
      ({AccessCodeComponent}) => ({default: AccessCodeComponent})
    )
  ),
  Button: lazy(() =>
    import('@backstage-components/button').then(({ButtonComponent}) => ({
      default: ButtonComponent,
    }))
  ),
  Checkbox: lazy(() =>
    import('@backstage-components/checkbox').then(({CheckboxComponent}) => ({
      default: CheckboxComponent,
    }))
  ),
  CnnQuiz: lazy(() =>
    import('@backstage-components/cnn-quiz').then(({CnnQuizComponent}) => ({
      default: CnnQuizComponent,
    }))
  ),
  CnnxLanding: lazy(() =>
    import('@backstage-components/cnnx-landing').then(
      ({CnnxLandingComponent}) => ({
        default: CnnxLandingComponent,
      })
    )
  ),
  Countdown: lazy(() =>
    import('@backstage-components/countdown').then(({CountdownComponent}) => ({
      default: CountdownComponent,
    }))
  ),
  Image: lazy(() =>
    import('@backstage-components/image').then(({ImageComponent}) => ({
      default: ImageComponent,
    }))
  ),
  Intercom: lazy(() =>
    import('@backstage-components/intercom').then(({IntercomComponent}) => ({
      default: IntercomComponent,
    }))
  ),
  Link: lazy(() =>
    import('@backstage-components/link').then(({LinkComponent}) => ({
      default: LinkComponent,
    }))
  ),
  PfLockerRoom: lazy(() =>
    import('@backstage-components/pf-locker-room').then(
      ({PfLockerRoomComponent}) => ({
        default: PfLockerRoomComponent,
      })
    )
  ),
  PhotoboothGallery: lazy(() =>
    import('@backstage-components/photobooth-gallery').then(
      ({PhotoboothGalleryComponent}) => ({
        default: PhotoboothGalleryComponent,
      })
    )
  ),
  QRCode: lazy(() =>
    import('@backstage-components/qr-code').then(({QRCodeComponent}) => ({
      default: QRCodeComponent,
    }))
  ),
  Radio: lazy(() =>
    import('@backstage-components/radio').then(({RadioComponent}) => ({
      default: RadioComponent,
    }))
  ),
  RichText: lazy(() =>
    import('@backstage-components/rich-text').then(({RichTextComponent}) => ({
      default: RichTextComponent,
    }))
  ),
  Switch: lazy(() =>
    import('@backstage-components/switch').then(({SwitchComponent}) => ({
      default: SwitchComponent,
    }))
  ),
  TextInput: lazy(() =>
    import('@backstage-components/text-input').then(({TextInputComponent}) => ({
      default: TextInputComponent,
    }))
  ),
  TitleCard: lazy(() =>
    import('@backstage-components/title-card').then(({TitleCardComponent}) => ({
      default: TitleCardComponent,
    }))
  ),
  Video: lazy(() =>
    import('@backstage-components/video').then(({VideoComponent}) => ({
      default: VideoComponent,
    }))
  ),
  Grid: lazy(() =>
    import('@backstage-components/grid').then(({GridComponent}) => ({
      default: GridComponent,
    }))
  ),
  Chat: lazy(() =>
    import('@backstage-components/chat').then(({ChatComponent}) => ({
      default: ChatComponent,
    }))
  ),
  // DropDown: placeholder,
  // Modal: placeholder,
  // MoreInfoOverlay: placeholder,
  // Overlay: placeholder,
  // PhotoboothContainer: placeholder,
  // PhotoboothGallery: placeholder,
} as const;

export const ComponentController: VFC<ContentComponent> = (definition) => {
  if (componentList[definition.component]) {
    // Not sure how to make type of `DefineComponent` match `definition` but
    // `componentList` properly checks that only an appropriate Component can be
    // assigned so should be safe
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const DefinedComponent: any = componentList[definition.component];
    return (
      <Suspense fallback={<Fragment />}>
        <DefinedComponent {...definition} />
      </Suspense>
    );
  } else {
    return <div>{definition.component}</div>;
  }
};

export default ComponentController;
