import {extractModuleIdentifiers, ModuleNode} from '@backstage-components/base';
import React, {VFC} from 'react';
import {SchemaType} from './RichTextDefinition';
import {IRichTextProps, RichTextComponent} from './RichTextComponent';

export type RichTextProps = SchemaType & IRichTextProps;

export const RichTextPreviewComponent: VFC<
  ModuleNode<'RichText', RichTextProps>
> = (definition) => {
  const {props} = definition.module;
  return (
    <RichTextComponent
      component={definition.module.component}
      config={{scope: 'admin'}}
      {...extractModuleIdentifiers(definition.module)}
      props={props}
    />
  );
};
