import {css} from '@emotion/react';
import {Element} from 'domhandler';
import {ContentModule, ModuleProperties} from '@backstage-components/base';
import React, {Fragment, useMemo, VFC} from 'react';
import {SchemaType} from './RichTextDefinition';
import parse, {DOMNode} from 'html-react-parser';

export interface IRichTextProps extends ModuleProperties {
  content: string;
}

export type RichTextComponentDefinition = ContentModule<
  'RichText',
  IRichTextProps & SchemaType
>;

/**
 * Checks if the given `domNode` is a `<script>` element.
 * @param domNode to check for `tagName`
 * @returns `true` iff `domNode` is `<script>` element.
 */
function isScriptElement(domNode: DOMNode): domNode is Element {
  return (
    domNode instanceof Element && domNode.tagName.toLowerCase() === 'script'
  );
}

export const RichTextComponent: VFC<RichTextComponentDefinition> = (
  definition
) => {
  const {content, styleAttr} = definition.props;
  const children = useMemo(
    () =>
      parse(content, {
        replace: (domNode) => {
          if (isScriptElement(domNode)) {
            return <Fragment />;
          }
        },
      }),
    [content]
  );
  return (
    <div
      id={definition.id}
      css={css`
        ${definition.style}
        ${styleAttr}
      `}
    >
      {children}
    </div>
  );
};
