export {AccessCodeComponent} from './AccessCodeComponent';
export type {AccessCodeComponentDefinition} from './AccessCodeComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  AccessCodeInstructionSchema,
  ComponentDefinition,
} from './AccessCodeDefinition';
export type {SchemaType} from './AccessCodeDefinition';
export {AccessCodePreviewComponent} from './AccessCodePreviewComponent';
export type {AccessCodeProps} from './AccessCodePreviewComponent';

import {Registry} from '@backstage-components/base';
import {AccessCodeModule} from './AccessCodeModule';
import {ComponentDefinition} from './AccessCodeDefinition';
Registry.register(ComponentDefinition, AccessCodeModule);
