import {gql} from '@apollo/client';

export const GetAttendeeQuery = gql`
  query GetAttendee($attendeeId: UUID!, $accessCodeId: UUID) {
    attendeeById(id: $attendeeId) {
      id
      name
      email
      chatTokens {
        token
      }
      accessCodes(accessCodeId: $accessCodeId) {
        attendeeTags
      }
    }
  }
`;

export const VerifyAccessCodeMutation = gql`
  mutation VerifyAccessCode($showId: UUID!, $accessCode: String!) {
    verifyAccessCode(data: {showId: $showId, accessCode: $accessCode}) {
      id
      showId
      sessionToken
      attendeeTags
      attendee {
        id
        name
        email
        chatTokens {
          token
        }
      }
    }
  }
`;
