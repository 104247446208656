import {
  ComponentAdmin,
  PublishesTo,
  SubscribesTo,
} from '@backstage-components/base';
import {Type} from '@sinclair/typebox';

export const reactName = 'Router';
export const name = reactName;

export const RouterInstructionSchema = Type.Union([
  SubscribesTo({
    topic: `${name}:goto`,
    description: 'Navigate the application to a given path.',
    meta: {
      path: Type.String({
        title: 'Path',
        description: 'The location to which the Router should go.',
      }),
    },
  }),
  PublishesTo({
    topic: `${name}:on-navigate`,
    description:
      'Used internally by ShowInstructionsProvider to determine current page for "about" selectors.',
    meta: {
      currentPath: Type.String({
        title: 'Current Path',
        description: 'The current path of the site.',
      }),
    },
    options: {
      '$lcd-flow-ignore': true,
    },
  }),
  PublishesTo({
    topic: `${name}:on-navigate-done`,
    description: 'Published when navigation to a new route has completed.',
    meta: {
      currentPath: Type.String({
        title: 'Current Path',
        description: 'The current path of the site.',
      }),
    },
  }),
]);

export const ComponentDefinition: ComponentAdmin = {
  id: '29167ee6-eb1f-4e27-8c9d-fe0b6b70668b',
  reactName,
  name,
  slug: reactName,
  description: 'Manages routing between pages.',
  version: 1,
  defaultFieldData: {},
  slotConfiguration: {},
  instructions: RouterInstructionSchema,
};
