import {DynamicComponent} from '@backstage-components/base';
import styled from '@emotion/styled';
import React, {Fragment, VFC} from 'react';
import {ComponentController} from '../parser/componentController';
import {LayoutController} from '../parser/layoutController';
import {isContent, isLayout} from '../types/dynamicComponent.types';

const Root = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const DynamicLayoutComponent: VFC<DynamicLayoutRootProps> = (props) => {
  const itemsLength = 100 / props.components.length;
  return (
    <Root>
      {props.components.map((component) => {
        const enableFlexShorthand =
          isLayout(component) &&
          'autoLayout' in component.props &&
          component.props.autoLayout;
        const flexShorthand = enableFlexShorthand
          ? `flex: 1 1 ${itemsLength}%;`
          : '';
        const componentStyle = component.style
          ? `${component.style}; ${flexShorthand}`
          : `${flexShorthand}`;
        if (isLayout(component)) {
          return (
            <LayoutController
              key={component.mid}
              {...component}
              style={componentStyle}
            />
          );
        } else if (isContent(component)) {
          return (
            <ComponentController
              key={component.mid}
              {...component}
              style={componentStyle}
            />
          );
        } else {
          return <Fragment key={component.mid} />;
        }
      })}
    </Root>
  );
};

interface DynamicLayoutRootProps {
  components: DynamicComponent[];
}

export default DynamicLayoutComponent;
