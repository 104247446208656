export {RichTextComponent} from './RichTextComponent';
export type {RichTextComponentDefinition} from './RichTextComponent';
export {
  defaultFieldData,
  schema,
  uiSchema,
  ComponentDefinition,
} from './RichTextDefinition';
export type {SchemaType} from './RichTextDefinition';
export type {RichTextProps} from './RichTextPreviewComponent';
export {RichTextPreviewComponent} from './RichTextPreviewComponent';

import {Registry} from '@backstage-components/base';
import {RichTextModule} from './RichTextModule';
import {ComponentDefinition} from './RichTextDefinition';
Registry.register(ComponentDefinition, RichTextModule);
